<template>
	<div class="hx-settings">

		<div class="order" style="width: 100%;">
			<el-row style="padding-bottom: 10px;border-bottom: 2px solid #0D8EA4;display: flex;align-items: center;">
				<el-col :span="12">
					<div class="orderno" style="width: 100%;"><span style="font-weight: bolder;">订单编号:{{orderNum}}</span></div>
				</el-col>
				<!-- <el-col :span="12">
					<div class="pt"><el-button @click="printOrder"><i class="el-icon-printer"></i> 打印</el-button></div>
				</el-col> -->
			</el-row>
			<el-row type="flex" class="row-bg" justify="space-around">
			  <el-col  :span="9" :offset="3">
				  <div class="conditions" align="left">
				  	<h3>患者信息</h3>
				  	<div class="hzInfo">
				  		<p><span class="cu">患者姓名:</span> {{patientNum}}</p>
				  		<p><span class="cu">出生日期:</span> {{patientBirthday}}</p>
				  		<p><span class="cu">性别:</span> {{patientGender}}</p>
				  	</div>
				  	<h3>医师信息</h3>
				  	<div class="hzInfo">
				  		<p><span class="cu1">医师姓名:</span> {{dname}}</p>
				  		<p><span class="cu1">手机号码:</span> {{phone}}</p>
				  		<p><span class="cu1">城市:</span> {{stateName}}</p>
				  		<p><span class="cu1">单位名称:</span> {{company}}</p>
				  	</div>
				  	<h3 >收件地址信息</h3>
				  	<div class="hzInfo">
				  		<p><span class="cu">收货人:</span> {{shouhuoUserName}}</p>
				  		<p><span class="cu">联系方式:</span> {{shouhuoPhone}}</p>
				  		<p><span class="cu">收货城市:</span> {{shouhuoCityInfo}}</p>
				  		<p><span class="cu">收货地址:</span> {{shouhuoAddress}}</p>
				  	</div>
				  </div>
			  </el-col>
			  <el-col :span="11" >
				  <div class="right" v-if="special==true" style="padding-top: 20px;">
				     <div v-html="beizhu"></div>
				  </div>
				  <div class="right" v-if="special==false">
				  	<!-- 上开始 -->
				  	<div class="top">
				  		<div class="hzInfo">
				  			<!-- <h3 style="display: inline-block;position: relative;left: -5px;">矫治方案</h3> -->
				  			<h3 >矫治方案</h3>
				  			<!-- <p><span class="cu">基托主颜色:</span> <p style="text-indent: 1em;" v-for="item in jituo">{{item}}</p></p> -->
				  			
				  			<!-- <p><span class="cu">选择的产品:</span> <p style="text-indent: 1em;" v-for="item in chanpin">{{item}}</p></p> -->
				  			<div style="display: flex;align-items: baseline;padding-left: 30px;">
				  				<span class="cu" style="text-align: left;min-width: 110px;">矫治器类型:</span>
				  				<div> <p style="text-indent: 1em;" v-for="item in chanpin">{{item}}</p></div>
				  			</div>
				  			<div style="display: flex;align-items: baseline;padding-left: 30px;">
				  				<span class="cu" style="text-align: left;min-width: 110px;">基托主颜色:</span>
				  				<div ><p style="text-indent: 1em;" v-for="item in jituo">{{item}}</p></div>
				  			</div>
				  			<!-- <p><span class="cu">矫治器类型:</span> <p style="text-indent: 1em;">{{leixing}}</p></p> -->
				  			<!-- <p><span class="cu">治疗目标:</span> <p v-if="planDoctor!=null" style="text-indent: 1em;">{{planDoctor}}</p></p>
				  			
				  			<p style="text-indent: 1em;" v-for="item in targetList">
				  				 {{item}}
				  			</p> -->
				  			<div style="display: flex;align-items: baseline;padding-left: 30px;">
				  				<span class="cu" style="text-align: left;min-width: 110px;">原始治疗方案:</span> 
				  				<div><p v-if="planDoctor!=null" style="margin-left: 13px;margin-bottom: 3px;">{{planDoctor}}</p></div>
				  			</div>
				  			<div style="padding: 0px !important;margin: 0px !important;" v-if="targetList !==undefined && targetList != null&&targetList.length>0">
				  				 <!-- <span style="text-indent: 6em;display: inline-block;font-weight: bolder;padding-left: 3px;">管理员治疗方案</span> -->
				  				<p style="margin: 0px !important;width: 400px;margin-left:170px;" v-for="item in targetList">
				  					 <span style="display: inline-block;line-height: 30px;" v-html="item"></span>
				  				</p>
				  			</div>
				  			<div v-if="planPdf!=null" style="padding: 0px !important;">
				  				<!-- <a style="color: #2C3E50;cursor: pointer;" class="cu">牙位详图</a> -->
				  				<!-- <h3 style="display: inline-block;min-width: 95px;text-align: right;margin: 0px !important;">牙位详图&nbsp;</h3>&nbsp; -->
				  				<h3 style="display: inline-block;">牙位详图</h3>
				  				<a style="color: darkgray;cursor: pointer;" class="cu"  @click="xiaZaiYaWei(planPdf)">(点击下载)</a>
				  				<p style="text-indent: 2em;margin: 0px !important;margin-top: 10px;" v-if="planPdf!=null"><img style="width: 300px;" :src="planPdf" /></p>
				  			</div>
				  		    
				  		</div>
				  	</div>
				  	<!-- 上结束 -->
				  
				  	<!-- 下开始 -->
				  	<div class="bottom">
				  		<div>
				  			<h3>X光片</h3>
				  			<el-table
				  			      :data="xRay"
				  			      style="width: 100%;border-radius: unset;">
				  			      <el-table-column
				  			        prop="type"
				  			        label="文件类型"
				  			       >
				  			      </el-table-column>
				  			      <el-table-column
				  			        prop="date"
				  			        label="成像日期"
				  			       >
				  			      </el-table-column>
				  			      <el-table-column
				  			        label="预览" align="center">
				  					 <template slot-scope="scope">
				  						 <a :href="scope.row.href" target="_blank" v-if="scope.row.yulan==true"><i style="color: #0D8EA4;font-size: 16px;"  class="el-icon-search"></i></a>
				  					  </template>
				  			      </el-table-column>
				  				  <el-table-column
				  				    label="下载"
				  					align="center"
				  					>
				  					<template slot-scope="scope">
				  						<span @click="xiazai(scope.row.href)"><i style="color: #0D8EA4;font-size: 18px;" class="el-icon-download"></i></span>   
				  					</template>
				  				  </el-table-column>
				  			    </el-table> 
				  		</div>
				  		<div>
				  			<h3>口扫文件</h3>
				  			<el-table
				  			      :data="ks"
				  			      style="width: 100%;border-radius: unset;">
				  			      <el-table-column
				  			        prop="type"
				  			        label="文件类型"
				  			       >
				  			      </el-table-column>
				  			      <el-table-column
				  			        prop="date"
				  			        label="成像日期"
				  			       >
				  			      </el-table-column>
				  			      <el-table-column
				  			        label="预览" align="center">
				  					 <template slot-scope="scope">
				  						 <a :href="scope.row.href" target="_blank" v-if="scope.row.yulan==true"><i style="color: #0D8EA4;font-size: 16px;"  class="el-icon-search"></i></a>
				  					  </template>
				  			      </el-table-column>
				  				  <el-table-column
				  				    label="下载"
				  					align="center"
				  					>
				  					<template slot-scope="scope">
				  						<span @click="xiazai(scope.row.href)"><i style="color: #0D8EA4;font-size: 18px;" class="el-icon-download"></i></span>   
				  					</template>
				  				  </el-table-column>
				  			    </el-table> 
				  		</div>
				  		<div>
				  			<h3>口内照片</h3>
				  			<el-table
				  			      :data="kn"
				  			      style="width: 100%;border-radius: unset;">
				  			      <el-table-column
				  			        prop="type"
				  			        label="文件类型"
				  			       >
				  			      </el-table-column>
				  			      <el-table-column
				  			        prop="date"
				  			        label="成像日期"
				  			       >
				  			      </el-table-column>
				  			      <el-table-column
				  			        label="预览" align="center">
				  					 <template slot-scope="scope">
				  						 <a :href="scope.row.href" target="_blank" v-if="scope.row.yulan==true"><i style="color: #0D8EA4;font-size: 16px;"  class="el-icon-search"></i></a>
				  					  </template>
				  			      </el-table-column>
				  				  <el-table-column
				  				    label="下载"
				  					align="center"
				  					>
				  					<template slot-scope="scope">
				  						<span @click="xiazai(scope.row.href)"><i style="color: #0D8EA4;font-size: 18px;" class="el-icon-download"></i></span>   
				  					</template>
				  				  </el-table-column>
				  			    </el-table> 
				  		</div>
				  		<div >
				  			<h3>面相照片</h3>
				  			<el-table
				  			      :data="mx"
				  			      style="width: 100%;border-radius: unset;">
				  			      <el-table-column
				  			        prop="type"
				  			        label="文件类型"
				  					min-width="100"
				  			       >
				  			      </el-table-column>
				  			      <el-table-column
				  			        prop="date"
				  			        label="成像日期"
				  			       >
				  			      </el-table-column>
				  			      <el-table-column
				  			        label="预览" align="center" >
				  					 <template slot-scope="scope">
				  						 <a :href="scope.row.href" target="_blank" v-if="scope.row.yulan==true"><i style="color: #0D8EA4;font-size: 16px;"  class="el-icon-search"></i></a>
				  					  </template>
				  			      </el-table-column>
				  				  <el-table-column
				  				    label="下载"
				  					align="center"
				  					
				  					>
				  					<template slot-scope="scope">
				  						<span @click="xiazai(scope.row.href)"><i style="color: #0D8EA4;font-size: 18px;" class="el-icon-download"></i></span>   
				  					</template>
				  				  </el-table-column>
				  			    </el-table> 
				  		</div>
				  		
				  	</div>
				  	<!-- 下结束 -->
				  </div>
			  </el-col>
			</el-row>
			
			
		</div>

		<div class="f">Copyright&copy;www.techlion.com.cn All Rights Reserved<br />山东泰克莱恩 0513-88762550</div>
	</div>
</template>

<script>
	import {
		Message
	} from 'element-ui';
	export default {
		data: function() {
			return {
				special:false,
				beizhu:'',
				targetList:[],
				shouhuoUserName:'',
				shouhuoPhone:'',
				shouhuoAddress:'',
				shouhuoCityInfo:'',
				dname:'',
				phone:'',
				company:'',
				stateName:'',
				orderNum:'',
				jituo:[],
				planPdf:null,
				chanpin:[],
				wrongMessages:[],
				patientNum: '',
				patientBirthday: '',
				patientGender: '',
				leixing: '',
				planDoctor: '',
				xRay:[],
				xRayCompleted: false,
				ks:[],
				ksCompleted: false,
				kn:[],
				knCompleted: false,
				mx:[],
				mxCompleted: false,
				yidongCompleted:false,
				liuyan:'',
				totalCompleted:false,
				ruleForm: {
					oid: ''
				},
				
				zuoshanglist: [], //储的左上哪些牙要移动
				youshanglist: [], //储的右上哪些牙要移动
				youxialist: [], //存储的右下哪些牙要移动
				zuoxialist: [], //储的左下哪些牙要移动
				checkList: [],
			}
		},
		mounted:function(){
			//设置默认地址
			sessionStorage.setItem("location", "/hxmain/hxorderyulan")
		},
		methods: {
			//打印
			printOrder(){
				var oid=sessionStorage.getItem("yloid");
				window.open("http://blht.techlion.com.cn:8089/client/order/downloadOrder?oid="+oid)
			},
			//下载牙位图片
			xiaZaiYaWei(url){
				window.open("http://120.26.66.95:8088/admin/orderAdmin/downloadQN?url="+url)
			},
			//下载资源图片
			xiazai(url){
				window.open("http://120.26.66.95:8088/admin/orderAdmin/downloadQN?url="+url)
			},
			//返回
			back(){
				this.$router.push("/hxmain/hxlatestorder")
			},
			//下一步
			nextStepOrder() {
				console.log("订单是否通过: "+this.totalCompleted);
				if(this.totalCompleted=="true"){
					this.$store.commit("setHxStepIndex",10);
					this.$router.push("/hxmain/hxorder/hxorderten");
				}else{
					this.$alert("订单填写不完整","提示");
				}
			}

		},
		created: function() {
			document.getElementsByClassName("print")[0].setAttribute("class","print show")
			document.getElementsByClassName("saveb")[0].setAttribute("class","saveb die")
				document.getElementsByClassName("fh")[0].setAttribute("class","fh show");
				/* document.getElementsByClassName("fun1")[0].setAttribute("class","fun1 sub-menue1 ")
				document.getElementsByClassName("fun")[0].setAttribute("class","fun sub-menue ")
				document.getElementsByClassName("fun2")[0].setAttribute("class","fun2 sub-menue2 visible");
			document.getElementsByClassName("fun3")[0].setAttribute("class","fun3 sub-menue3") */
			//如果是编辑订单要获取oid的值
			let oid = sessionStorage.getItem("yloid");
			
			if (oid != "undefined" && oid != '' && oid != null) {
				this.ruleForm.oid = oid;
				this.$axios.get("/client/order/hxYulan", {
					params: {
						oid: oid
					}
				}).then(res => {
					console.log(res.data.data);
					let confirmInfo = res.data.data.orderInfo;
					console.log(confirmInfo.orderNum);
					this.special=res.data.data.special;
					this.beizhu=res.data.data.beizhu;
					//订单编号
					if(confirmInfo.orderNum==null){
						this.orderNum='暂无'
					}else{
						this.orderNum=confirmInfo.orderNum;
					}
					//医师信息
					this.dname=confirmInfo.doctor.dname;
					this.phone=confirmInfo.doctor.phone;
					this.company=confirmInfo.doctor.company;
					this.stateName=confirmInfo.doctor.stateName;
					//收货地址信息
					this.shouhuoUserName=confirmInfo.address.userName;
					this.shouhuoPhone=confirmInfo.address.phone;
					this.shouhuoCityInfo=confirmInfo.address.cityInfo;
					this.shouhuoAddress=confirmInfo.address.address;
					this.jituo=confirmInfo.jituo;
					this.chanpin=confirmInfo.chanpin;
					this.planPdf=confirmInfo.planPdf;
					if (confirmInfo.patientNum != null && confirmInfo.patientNum != "") {
						this.patientNum = confirmInfo.patientNum;
					}
					if (confirmInfo.patientBirthday != null && confirmInfo.patientBirthday != "") {
						this.patientBirthday = confirmInfo.patientBirthday;
					}
					if (confirmInfo.patientGender != null && confirmInfo.patientGender != "") {
						this.patientGender = confirmInfo.patientGender;
					}
					if (confirmInfo.jzqlx != null && confirmInfo.jzqlx != '') {
						this.leixing = confirmInfo.jzqlx;
					}
					if(res.data.data.targetList!=null){
						this.targetList=res.data.data.targetList;
					}
					   
						this.planDoctor = confirmInfo.planDoctor;
					
					
						this.xRay = confirmInfo.xray;
					
					
						this.ks = confirmInfo.kousao;
					
					
						this.kn = confirmInfo.kounei;
					
					
						this.mx = confirmInfo.mianxiang;
					
					
					//留言
					// this.liuyan=confirmInfo.plan.liuyan;
					//未填写信息
					this.wrongMessages=res.data.data.wrongMessages;
					console.log(this.wrongMessages);
					//验证是否通过
					this.totalCompleted=res.data.data.checked;
				}).catch(err => {
					console.log(err);
				});
			}else{
				
			}
			//将当前路径存储到backLocation
			this.$store.commit("setBackLocation", "/hxmain/hxlatestorder");
		}
	}
</script>

<style scoped="scoped">
	.hx-settings {
		width: 100%;
		height: 100%;
		background-color: transparent;
		overflow-y: auto;
		overflow-x: hidden;
		position: relative;
		margin-bottom: 1.875rem;
		/* z-index: 0; */
	}

	h2 {
		color: #0D8EA4;
	}
	
	.conditions p {
		padding-left: 25px;
	}

	.nextStep {
		background-color: #0D8EA4 !important;
		border-color: #0D8EA4 !important;
		color: #fff !important;
	}
.orderno{
		font-size: 20px;
		text-align: left;
		/* border-bottom: 2px solid #0D8EA4; */
		/* margin-bottom: 30px; */
		/* padding-bottom: 10px; */
	}
	.pt{
		text-align: right;
	}
	.pt .el-button{
		background-color: #0D8EA4 !important;
		/* border-color: #0D8EA4 !important; */
		border: none;
		color: #fff !important;
		min-width: 6rem;
	}
	.order {
		position: relative;
		background-color: white;
		
		width: 90%;
		min-height: 31.75rem;
		margin-top: 11rem;
		margin-bottom: 15.75rem;
		padding-top: 1.25rem;
		padding-bottom: 10px;
		display: flex;
		 flex-direction: column;
		 align-items: center;
		justify-content: center;
	}



	/* 条件 */
	.conditions {
		box-sizing: border-box;
		/* background-color: #f0f0ee; */
		/* border-radius: 15px; */
		/* padding: 10px 20px 20px 20px; */
		/* min-width: 500px; */
		/* margin: 0px auto; */
		/* margin-left: 10%;
		padding-left: 100px; */
		/* position: relative;
		left: -100px; */
		/* background-color: green; */
	}

	.right {
		/* min-width: 500px; */
		min-height: 31.75rem;
		/* position: relative;*/
		text-align: left; 
		 /* background-color: red; */ 
	}

	.ress {
		/* border: 1px solid red; */
		/* background-color: lightgray; */
		/* border-radius: 15px; */
		padding-left: 10px;
		height: 120px;

	}

	

	.primary {
		margin: 0px 0px 1px;
		padding: 6px 12px 6px 12px;
		background-color: #0D8EA4 !important;
		border-color: #0D8EA4 !important;
		color: #fff !important;
		border-radius: 0px;
		width: 5.125rem;
		height: 2.3rem;
	}

	.f {
		position: relative;
		background-color: #444;
		padding: 10px 0 10px 0;
		color: #fff;
		bottom: 0rem;
	}
	.btj{
		min-width: 7.5rem;
		background-color: transparent;
		border-color: #0D8EA4 !important;
		color: #0D8EA4 !important;
	}
	.cu{
		font-weight: 550;
		 min-width: 95px;
		text-align: right;
		display: inline-block;
	}
	.cu1{
		display: inline-block;
		font-weight: 550;
		width: 85px;
		text-align: right;
	}
	/*  */
	
	.el-row {
		width: 90%;
		margin: 0px auto;
	    margin-bottom: 20px;
	    &:last-child {
	      margin-bottom: 0;
	    }
	  }
	  .el-col {
	    border-radius: 4px;
	  }
	  .bg-purple-dark {
	    background: #99a9bf;
	  }
	  .bg-purple {
	    background: #d3dce6;
	  }
	  .bg-purple-light {
	    background: #e5e9f2;
	  }
	  .grid-content {
	    border-radius: 4px;
	    min-height: 36px;
	  }
	  .row-bg {
		 
	    padding: 10px 0;
	   /* background-color: blueviolet; */
	  }
</style>
